import { toast } from "react-toastify";
import { useWalletContext } from "../context/walletContext"
import { useEffect, useRef } from "react";



export default function DisclaimerModal(){
    const modalRef = useRef();
    const {showDisclaimer, setShowDisclaimer,  setDisclaimerAccepted} = useWalletContext()

    const walletOpen = () => {
        setDisclaimerAccepted(true)
        setShowDisclaimer(false)
    }

   const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowDisclaimer(false);
    }
};

useEffect(() => {
    // Add event listener for outside clicks
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
        // Clean up the event listener
        document.removeEventListener('mousedown', handleOutsideClick);
    };
}, [])

    
    return(
       <>
        <div id="default-modal" tabindex="-1" onClick={()=>{setShowDisclaimer(false)}} aria-hidden="true" class={showDisclaimer ? "flex bg-black/30 backdrop-blur-sm fixed top-0 right-0 left-0  z-50 justify-center items-center w-full md:inset-0 h-full" : "hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"}>
            <div class="relative p-4 w-full max-w-2xl flex justify-center items-center">
                {/* <!-- Modal content --> */}
                <div class="relative  rounded-lg shadow bg-neutral-900">
                    {/* <!-- Modal header --> */}
                    <div class="flex items-center justify-between  p-4 md:p-5 border-b rounded-t border-gray-700">
                        <h3 class="text-xl font-semibold text-white ">
                            Disclaimer
                        </h3>
                        <button onClick={() =>setShowDisclaimer(false)} type="button" class="text-gray-200 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-neutral-700 hover:text-white" data-modal-hide="default-modal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                    {/* <!-- Modal body --> */}
                    <div class="p-4 md:p-5 space-y-4 scrollbar scrollbar-thumb-neutral-900 scrollbar-track-neutral-800 max-h-[500px] overflow-y-auto">
                        <p class="text-base leading-relaxed text-gray-200">
                            <b>Welcome to XXX</b>
                        </p>
                        <p class="text-base leading-relaxed text-gray-200">
                            To deposit, connect your Phantom wallet to the dApp after which you are able to complete your deposit.
                        </p>
                        <p className="mb-4 text-gray-300">
                            Please note that when claiming your earnings, you will no longer receive future earnings until you make a new deposit. However if you redeposit your earnings, or add a new deposit you will still receive earnings from multiple deposits up until you claim.
                        </p>

                        <p className="mb-4 text-gray-300">
                            For further explanation on the workings of this experiment make sure to read: <span className="font-semibold">XXX</span>
                        </p>

                        <p className="mb-4 text-gray-300">
                            Always verify the contract address you are interacting with to be the correct one:
                            <a href='https://solonfomo.com/' className="font-semibold">XXX</a>
                        </p>

                        <p className="mb-4 text-gray-300">
                            Always verify the dApp domain and URL you are interacting with to be the correct one:
                           
                            <a href='https://solonfomo.com/' className="font-semibold"> https://solonfomo.com/</a>

                        </p>

                        <p>
                            <b className="text-white">DISCLAIMER:</b>
                        </p>

                        <p className="mb-4 text-gray-300">
                            
                            This project is an experimental stress test for the Solana blockchain. 
                        </p>

                        <p className="mb-4 text-gray-300">
                            Any amount of SOL deposited into the smart contract is a permanent contribution to and sacrifice for this experiment and will NOT be returned.
                        </p>

                        <p className="mb-4 text-gray-300">

                            Any unclaimed earnings should be regarded as non-existent.
                        </p>

                        <p className="mb-4 text-gray-300">
                            The control and ownership to the smart contract of this experiment is revoked and no one has access to or control over any addresses holding rewards. Therefore no person or legal entity can be held accountable or holds responsibility for any events or developments within this experiment.
                        </p>

                        <div className="mb-4  text-gray-300">
                            <p className="mb-2">

                                <b>DO NOT CONNECT YOUR WALLET</b> or participate in this experiment if you do not wish to lose the deposited amount.
                            </p>

                            <p className="mb-2">

                                <b>DO NOT CONNECT YOUR WALLET</b> or participate in this experiment if you haven’t conducted your own research about the experiment and studied its rules and contract code.
                            </p>

                            <p className="mb-2">

                                <b>DO NOT CONNECT YOUR WALLET</b> or participate in this experiment if you are expecting profits.
                            </p>

                            <p className="mb-2">

                                <b>DO NOT CONNECT YOUR WALLET</b> or participate in this experiment if you are under 21 years of age.
                            </p>

                            <p className="mb-2">

                                <b>DO NOT CONNECT YOUR WALLET</b> or participate in this experiment if you are a citizen or resident of the USA.
                            </p>

                            <p className="mb-2">

                                <b>DO NOT CONNECT YOUR WALLET</b> or participate in this experiment if it is against your local laws to do so.
                            </p>

                            <p className="mb-2">
                                <b>DO NOT CONNECT YOUR WALLET</b> or participate in this experiment and/or start or use this bot if you haven’t read this disclaimer and/or if you do not understand, comply and/or agree with anything mentioned above.
                            </p>

                        </div>
                    </div>
                    {/* <!-- Modal footer --> */}
                    <div class="flex items-center p-4 md:p-5 border-t border-gray-700 rounded-b">
                        <button onClick={walletOpen} data-modal-hide="default-modal" type="button" class="text-white bg-[#B4992D] hover:bg-[#a48927e8] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">I accept</button>
                        <button onClick={() => {
                            setShowDisclaimer(false);
                            toast.error('You cannot Connect');
                        }} data-modal-hide="default-modal" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-white focus:outline-none bg-neutral-700 rounded-lg  hover:bg-neutral-600  ">Decline</button>
                    </div>
                </div>
            </div>
        </div>

       
       </>

    )
}