import * as anchor from "@project-serum/anchor";


// master:     J981v2uhjnKB8ArtVXBenD4uZVt3kNU8HNPP6hcpTuoe
// addresses:      CPBLbrVraEgiSdLgTJsoTFsyU1S7gSpFPVGgvJEXYjXK
// user:        tok83




const keys = {
    programId: "Dc8r58JXUZc2eS33y76bjMwDNbEsk3PHRBFgCWxUzMLF",
    master: new anchor.web3.PublicKey('B9c8LAnzKH4WtkCjnUZJvyEhisz64WUzQ6pHZZF8CSU'),
    address2: new anchor.web3.PublicKey('4xXvtwe8qLZLGUdbHmaWNnkeTcts2oozJyWDnWygwUGq'),
    address3: new anchor.web3.PublicKey('3bYzjrW1FXSdT35h2kCeSQbYqJkfi7yDqZDds9G7gd8y'),
    address4: new anchor.web3.PublicKey('4xXvtwe8qLZLGUdbHmaWNnkeTcts2oozJyWDnWygwUGq'),
    addresses: new anchor.web3.PublicKey('H7XmygT76pA5A6nKKPaXutFQVy1jyFLBmugnmZ7XbdM'),
   // url:"/api",
    url:"https://api.solonfomo.com"
     //url:"http://18.118.241.47"
};













export default keys;
