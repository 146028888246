import { AnchorProvider } from "@project-serum/anchor";
import * as anchor from "@project-serum/anchor";

import idl from '../back/idl2.json'

import * as web3 from '@solana/web3.js';
import keys from "../config";



export const fetchMasterAccountData = async () => {
    
    const connection = new web3.Connection(web3.clusterApiUrl('devnet'), 'confirmed')
    const programId = keys.programId;
    
    try {
        const provider = new AnchorProvider(connection, 'confirmed');
        const program = new anchor.Program(idl, programId, provider);
       
        
        const masterAccount = await program.account.master.fetch(keys.master);
       
        
        return {
            endSec: masterAccount.endSec.toString(),
            startSec:masterAccount.startSec.toString(),
            startTime: masterAccount.startTime.toString(),
            lastDepositId: masterAccount.lastDepositId.toString(),
            referralId: masterAccount.referralId.toString(),
            depositAmount: masterAccount.depositAmount.toString(),
            totalDeposited: masterAccount.totalDeposited.toString(),
            referralAmount: masterAccount.referralAmount.toString(),
            potAmount: masterAccount.potAmount.toString(),
            addrOneAmount: masterAccount.addrOneAmount.toString(),
            owner: masterAccount.owner.toString(),
            lastDepositor: masterAccount.lastDepositor.toString(),
            winnerAnnounced: masterAccount.winnerAnnounced
        }

    } catch (error) {
        console.error('Error fetching master account data:', error);
    }
}
