import {  WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import logo from '../images/logo.webp'
import { useWallet } from "@solana/wallet-adapter-react";
import CustomButton from "./customSelectWalletBtn";


export default function Header() {
  const {publicKey} = useWallet()


  return (
    <header>
      <nav class="border-gray-200 px-4 lg:px-6 py-2.5 bg-transparent z-50 ">
        <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-2xl">
          <a href="#" class="flex items-center">
            <img
              src={logo}
              class="mr-1 h-6 sm:h-9 rounded-lg"
              alt="solana Logo"
            />
            <span class="self-center text-xl font-semibold whitespace-nowrap text-white">
              SolOnFomo
            </span>
          </a>

          <div className="flex items-center ">
            
            {
              publicKey ?
              <WalletMultiButton  className="" />
              :
              <CustomButton />
            }
          </div>
          
        </div>
      </nav>
    </header>
  );
}
