import { useEffect, useRef, useState } from "react";

export default function Counter({ startCounter,startTime  }) {
   
    const [time, setTime] = useState(startTime);
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    

    const timer = useRef();

    const getTime = (time) => {
        const days = Math.floor(time / (3600 * 24));
        const hours = Math.floor((time % (3600 * 24)) / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);

        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
    };

    useEffect(() => {
        setTime(startTime)
        getTime(startTime);
        if (startCounter !== '0') {
            timer.current = setInterval(() => {
                setTime((prevTime) => {
                    if (prevTime <= 0) {
                        clearInterval(timer.current);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }
        
        return () => clearInterval(timer.current);
    }, [startTime, startCounter]);

    useEffect(() => {
        getTime(time);
    }, [time]);

    return (
        <>
            <p className="text-[#A48927] w-fit text-2xl md:text-3xl font-bold uppercase">
                {days <= 9 ? `0${days}` : days}d:
                {hours <= 9 ? `0${hours}` : hours}h:
                {minutes <= 9 ? `0${minutes}` : minutes}m:
                {seconds <= 9 ? `0${seconds}` : seconds}s
            </p>
        </>
    );
}
