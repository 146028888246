import React, { useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useWalletContext } from '../context/walletContext';
import DisclaimerModal from './disclaimerModal';


const CustomButton = ({ children }) => {
  const { publicKey } = useWallet();
  const { setShowDisclaimer, showDisclaimer, disclaimerAccepted ,setDisclaimerAccepted } = useWalletContext()
  const { setVisible } = useWalletModal()
  const handleClick = () => {

    setShowDisclaimer(true)

  };
  const handleModalVisible = () => {

    setVisible(true)
    setDisclaimerAccepted(false)
  }
  useEffect(() => {
    disclaimerAccepted && handleModalVisible()
  }, [disclaimerAccepted, publicKey])

  return (
    <>
      <button onClick={handleClick} className='py-2.5 px-4 bg-neutral-800 rounded-lg text-white'>
        {publicKey && !disclaimerAccepted ? 'Disconnect Wallet' : 'Connect Wallet'}
        {children}
      </button>
      {

        showDisclaimer ?
        <DisclaimerModal /> :null
      }
    </>
  );
};

export default CustomButton;
