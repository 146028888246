
// import "./App.css";
import LandingPage from "./pages/landing";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import * as web3 from "@solana/web3.js";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { useMemo } from "react";
import { WalletApi } from "./context/walletContext";
import { SolanaMobileWalletAdapter, createDefaultAddressSelector, createDefaultAuthorizationResultCache } from "@solana-mobile/wallet-adapter-mobile";

import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { createDefaultWalletNotFoundHandler } from "@solana-mobile/wallet-adapter-mobile";
function App() {
  
  const endpoint = web3.clusterApiUrl("devnet");

  const wallets = useMemo(() => [], [
    new SolanaMobileWalletAdapter({
      addressSelector: createDefaultAddressSelector(),
      appIdentity: {
          name: 'Phantom',
          uri: 'https://phantom.app/ul/v1/connect',
          // icon: 'relative/path/to/icon.png',
      },
      authorizationResultCache: createDefaultAuthorizationResultCache(),
      cluster: WalletAdapterNetwork.Devnet,
      onWalletNotFound: createDefaultWalletNotFoundHandler(),
  }),
  ]);

  return (
     
    <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          <WalletModalProvider>
            <WalletApi>
              <LandingPage />
            </WalletApi>
          </WalletModalProvider>
        </WalletProvider>
    </ConnectionProvider>
      
  );
}

export default App;
