
import * as anchor from "@project-serum/anchor";
import * as buffer from "buffer";

import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import * as web3 from "@solana/web3.js";
import { useEffect, useState } from "react";
import { useWalletContext } from "../context/walletContext";
import keys from "../config";
import { toast } from "react-toastify";
import axios from "axios";


export default function DepositBtn({depositAmountL,winnerTime}) {
  const wallet = useAnchorWallet();

  const { publicKey} = useWallet();
  const { connection, program, isDepDone, setIsDepDone, setClaimableDependency, claimableDpendency } = useWalletContext();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [amountDeposited, setAmountDeposited] = useState();
  const [totalDeposits, setTotalDeposits] = useState();
  const [convertedLampsToSolAmnt, setConvertedLtoSAmnt] = useState();


  window.Buffer = buffer.Buffer;

  
  
  const programId = keys.programId
  const master1= keys.master
  const address2= keys.address2
  const address3= keys.address3
  const address4= keys.address4
  const addresses= keys.addresses


 

  const getUserDeposits = async () => {
    const url = keys.url + '/depositor/user_deposits';
    const apiParams = {
        'wallet_address': publicKey?.toString(),
    };

    await axios.post(url, apiParams, {
        headers: {
            "Content-Type": "application/json",
        },
    })
    .then((resp) => {
        const responseData = resp.data;  // Axios automatically parses JSON
        setTotalDeposits(responseData.data);
    })
    .catch((err) => {
        console.log(err);
    });
}

  useEffect(()=> {
    getUserDeposits()
  }, [publicKey, isDepDone])

  
  const ConvertSolToLamp = (sol) => {
      return Math.round(sol * web3.LAMPORTS_PER_SOL, 9);
  }

  const ConvertLampToSol = (lamports) => {
    return lamports / web3.LAMPORTS_PER_SOL;
  }

  const convertedSolAmnt = ConvertSolToLamp(amountDeposited);
  

  useEffect(() => {
    const convertedLampsToSolAmnt = ConvertLampToSol(depositAmountL);
    setConvertedLtoSAmnt(convertedLampsToSolAmnt)
  }, [depositAmountL, claimableDpendency])

  

  const depositAmount = async () => {
    setIsProcessing(true);

      const SEED = 'tok3';

      const [pda, bump] = await web3.PublicKey.findProgramAddress(
        [
          Buffer.from(SEED),
          publicKey.toBuffer()
        ],
        new web3.PublicKey(programId)
      )

      
    
    try {
        
        const balance = await connection.getBalance(publicKey);
       

      if (!program || !connection)
        throw new Error("Program or connection not initialized");

      if (balance < convertedSolAmnt)
        throw new Error("Insufficient balance for transaction");

      // Fetch the latest blockhash
      const latestBlockhash = await connection.getLatestBlockhash("finalized");
     
      // Initialize a new account
      
      

      const tx = program.transaction.deposit( 
        new anchor.BN(convertedSolAmnt),
        {
        accounts: {
            master:master1,
            user:pda,
            addresses:addresses,
            addr2:address2,
            addr3:address3,
            addr4:address4,
            buyer:publicKey,   
            systemProgram: "11111111111111111111111111111111",
        }
       
      });

      const transaction = new web3.Transaction()
      transaction.add(tx)
      transaction.feePayer = publicKey;
      transaction.recentBlockhash = latestBlockhash.blockhash
      // signed Transaction
      const signedTx = await wallet.signTransaction(transaction)
      
      const signature = await connection.sendRawTransaction(signedTx.serialize());
      await connection.confirmTransaction(signature)
      const data={
        'wallet_address':publicKey.toString(),
          "amount":convertedSolAmnt
      }

      axios.post(`${keys.url}/depositor/deposit`,data).then((res)=>{
        
        if (res.data.status ===201){
          toast(res.data.message)
          setIsProcessing(false);
          setIsOpenModal(false);
          setIsDepDone(true);
          setClaimableDependency(!claimableDpendency)
          setAmountDeposited('');
        }
     

      
      
      }).catch((err)=>{
        toast.error(err.data.message)
       
         setIsProcessing(false);
      setIsOpenModal(false)
      setAmountDeposited('');
      setIsDepDone(false);
      })

     

      
     
    } catch (error) {
      
      toast(error?.message.toString());
     
      setIsProcessing(false);
      setIsOpenModal(false)
      setAmountDeposited('');
      setIsDepDone(false);
      setClaimableDependency(!claimableDpendency)


    }
  };
  return (
    <>
    {isProcessing ? 
        <button    type="button" class="py-[13px] w-full px-5 mt-4 text-sm font-medium  rounded-lg border   focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 bg-neutral-900 text-gray-400 border-gray-600 hover:text-white hover:bg-neutral-700 inline-flex justify-center items-center">
        <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3  animate-spin text-neutral-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#C9F649"/>
        </svg>
            Loading...
        </button>
        :
        <button
       
        
        onClick={() => {winnerTime ===0?toast("Time Ended"): setIsOpenModal(true)}}
        class="inline-flex cursor-pointer mt-4 w-full overflow-hidden text-ellipsis items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-[#A48927] hover:bg-[#a48927e8] active:bg-[#a48927da] focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
        >
          {
            totalDeposits > 0 ?
            'Redeposit'
            :
            'New Deposit'

          }
        </button>
    }


    {/* <!-- Main modal --> */}
      <div id="authentication-modal" tabindex="-1" aria-hidden="true" class={isOpenModal ? "flex bg-black/30 backdrop-blur-sm top-0 left-0 overflow-y-auto overflow-x-hidden fixed z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full": "hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"}>
        <div class="relative p-4 w-full max-w-md max-h-full">
            {/* <!-- Modal content --> */}
            <div class="relative rounded-lg shadow bg-[#0a0a0a] border border-[#353A47]">
                {/* <!-- Modal header --> */}
                <div class="flex items-center justify-between px-4 pt-4 md:px-5 md:pt-5 rounded-t">
                    <h3 class="text-xl font-semibold  text-white">
                        Add amount to be deposited
                    </h3>
                    <button onClick={() => setIsOpenModal(false)}  type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-neutral-800 hover:text-neutral-400 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center " data-modal-hide="authentication-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                {/* <!-- Modal body --> */}
                <div class="px-4 pb-4 md:px-5 md:pb-5 mt-2 ">
                    <form class="space-y-4" action="#">
                        <div>
                            <label for="amount" class="block mb-2 text-sm font-medium text-white">Your amount in SOL</label>
                            <input type="number" name="amount" id="amount" max={999} onChange={(e) => {
                              const validated = e.target.value.match(/^(\d*\.{0,1}\d{0,8}$)/)
                              const maxValid = e.target.value.match(/^.{6,7}$/)
                              if (validated || maxValid) {
                                
                                 setAmountDeposited(e.target.value);
                              }
                             
                            }} value={amountDeposited} class=" text-sm rounded-lg   w-full p-2.5 bg-neutral-800 placeholder-gray-400 text-white" placeholder="amount in sol" required />
                            {
                              amountDeposited > convertedLampsToSolAmnt ?
                              <></>
                              : 
                              <span className="text-white text-sm">The value should be greater than {convertedLampsToSolAmnt}</span>

                               
                            }
                        </div>
                      
                        {isProcessing ? 
                          <button disabled type="button" class="py-[13px] w-full px-5 mt-4 text-sm font-medium  rounded-lg border   focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 bg-neutral-900 text-gray-400 border-gray-600 hover:text-white hover:bg-neutral-700 inline-flex justify-center items-center">
                          <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3  animate-spin text-neutral-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#C9F649"/>
                          </svg>
                              Loading...
                          </button>
                          :
                          <button
                          onClick={depositAmount}
                          disabled={amountDeposited === undefined || amountDeposited < convertedLampsToSolAmnt}
                          class="inline-flex cursor-pointer mt-4 w-full overflow-hidden text-ellipsis items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white disabled:text-black rounded-lg bg-[#B4992D] hover:bg-[#a48927e8] active:bg-[#a48927da] focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900 disabled:bg-[#a48927e8] disabled:cursor-default"
                          >
                          Deposit Now
                          </button>
                      }
                        
                    </form>
                </div>
            </div>
        </div>
      </div> 

    </>
  );
}
